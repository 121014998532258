<template>
  <div>
    <h1>Berkstresser LLC Learning</h1>
    <div class="homeCards">
      <Card class="homeCard">
        <template #header>
          <img src="@/assets/dusty.gif" style="width:10em;" />
        </template>
        <template #title>Mission</template>
        <template #subtitle>Providing a quality, personalized education to those who are academically able but
          inadequately served by the public school system</template>
        <template #content>
          <p>
            Motivated students, no matter what age or subject, should have their chance at excellence. We can help.
          </p>

        </template>
        <!--
    <template #footer>
      <div class="flex gap-3 mt-1">
        <Button label="Cancel" severity="secondary" outlined class="w-full" />
        <Button label="Save" class="w-full" />
      </div>
    </template>
    -->
      </Card>
      <Card class="homeCard">
        <template #header>
          <img src="@/assets/dusty.gif" style="width:10em;" />
        </template>
        <template #title>About Us</template>

        <template #content>
          <p>
            Berkstresser Learning is a home-based tutoring service with:
          <ul>
            <li>A holistic approach to education</li>
            <li>A secular worldview</li>
            <li>A passion for teaching students who want to learn</li>
            <li>A passion for learning new things ourselves</li>
            <li>Over 25 years of combined education experience</li>
            <li>Grade levels: K-12 in core subjects. Some college GEs.</li>
            <li>Flexible scheduling</li>
            <li>Virtual tutoring available when the subject and grade level allow</li>
            <li>A relaxed home environment conveniently located near Branson, MO</li>
          </ul>
          </p>
        </template>

      </Card>
      <Card class="homeCard">
        <template #header>
          <img src="@/assets/dusty.gif" style="width:10em; " />
        </template>
        <template #title>Contact Us</template>

        <template #content>
          <p>
            Visit us on <a target="_blank" href="https://www.facebook.com/BerkstresserLLC">Facebook</a><br>
            Call or text us at <a href="tel:417-929-2533">417-929-2533</a>.<br>If you prefer email, <a
              href="mailto:cjberkstresser@gmail.com">cjberkstresser@gmail.com</a><br><br>
            We are located at:<br>
            160 Deer Ln.<br>
            Kirbyville, MO 65679<br><br>
            --just a little east of Branson, MO.

          </p>
        </template>

      </Card>
      <Card class="homeCard" style="min-width:95%">
        <template #title>Calendar</template>

        <template #content>
          <FullCalendar :options="calendarOptions" />
        </template>

      </Card>
    </div>
  </div>

</template>

<script lang="ts">
import { Calendar } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import iCalendarPlugin from '@fullcalendar/icalendar'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
export default {
  name: "HomePage",
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        plugins: [googleCalendarPlugin, dayGridPlugin],
        initialView: 'dayGridMonth',
        views: {
          multiMonthFourMonth: {
            type: 'multiMonth',
            duration: { months: 4 }
          }
        },
        googleCalendarApiKey: 'AIzaSyDV5E2i7rekViQrJ7LTKRfpwX6GEcz0Gic',
        events: {
          googleCalendarId: '323f27a39d935c3649d700508fd0c99f335a4ba700538dfb641aef9d332afb4e@group.calendar.google.com'
        }
      }
    }
  },
  methods: {
    handleDateClick: function (arg) {
      alert('date click! ' + arg.dateStr)
    }
  }
}
</script>

<script lang="ts" setup>
import Card from 'primevue/card';
import 'primeicons/primeicons.css';
import Carousel from 'primevue/carousel';
</script>