import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/dusty.gif'


const _hoisted_1 = { class: "homeCards" }

import Card from 'primevue/card';
import 'primeicons/primeicons.css';
import Carousel from 'primevue/carousel';

import { Calendar } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import iCalendarPlugin from '@fullcalendar/icalendar'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
const __default__ = {
  name: "HomePage",
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        plugins: [googleCalendarPlugin, dayGridPlugin],
        initialView: 'dayGridMonth',
        views: {
          multiMonthFourMonth: {
            type: 'multiMonth',
            duration: { months: 4 }
          }
        },
        googleCalendarApiKey: 'AIzaSyDV5E2i7rekViQrJ7LTKRfpwX6GEcz0Gic',
        events: {
          googleCalendarId: '323f27a39d935c3649d700508fd0c99f335a4ba700538dfb641aef9d332afb4e@group.calendar.google.com'
        }
      }
    }
  },
  methods: {
    handleDateClick: function (arg) {
      alert('date click! ' + arg.dateStr)
    }
  }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[11] || (_cache[11] = _createElementVNode("h1", null, "Berkstresser LLC Learning", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(Card), { class: "homeCard" }, {
        header: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_0,
            style: {"width":"10em"}
          }, null, -1)
        ])),
        title: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Mission")
        ])),
        subtitle: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Providing a quality, personalized education to those who are academically able but inadequately served by the public school system")
        ])),
        content: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("p", null, " Motivated students, no matter what age or subject, should have their chance at excellence. We can help. ", -1)
        ])),
        _: 1
      }),
      _createVNode(_unref(Card), { class: "homeCard" }, {
        header: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            src: _imports_0,
            style: {"width":"10em"}
          }, null, -1)
        ])),
        title: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("About Us")
        ])),
        content: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("p", null, [
            _createTextVNode(" Berkstresser Learning is a home-based tutoring service with: "),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, "A holistic approach to education"),
              _createElementVNode("li", null, "A secular worldview"),
              _createElementVNode("li", null, "A passion for teaching students who want to learn"),
              _createElementVNode("li", null, "A passion for learning new things ourselves"),
              _createElementVNode("li", null, "Over 25 years of combined education experience"),
              _createElementVNode("li", null, "Grade levels: K-12 in core subjects. Some college GEs."),
              _createElementVNode("li", null, "Flexible scheduling"),
              _createElementVNode("li", null, "Virtual tutoring available when the subject and grade level allow"),
              _createElementVNode("li", null, "A relaxed home environment conveniently located near Branson, MO")
            ])
          ], -1)
        ])),
        _: 1
      }),
      _createVNode(_unref(Card), { class: "homeCard" }, {
        header: _withCtx(() => _cache[7] || (_cache[7] = [
          _createElementVNode("img", {
            src: _imports_0,
            style: {"width":"10em"}
          }, null, -1)
        ])),
        title: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Contact Us")
        ])),
        content: _withCtx(() => _cache[9] || (_cache[9] = [
          _createElementVNode("p", null, [
            _createTextVNode(" Visit us on "),
            _createElementVNode("a", {
              target: "_blank",
              href: "https://www.facebook.com/BerkstresserLLC"
            }, "Facebook"),
            _createElementVNode("br"),
            _createTextVNode(" Call or text us at "),
            _createElementVNode("a", { href: "tel:417-929-2533" }, "417-929-2533"),
            _createTextVNode("."),
            _createElementVNode("br"),
            _createTextVNode("If you prefer email, "),
            _createElementVNode("a", { href: "mailto:cjberkstresser@gmail.com" }, "cjberkstresser@gmail.com"),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" We are located at:"),
            _createElementVNode("br"),
            _createTextVNode(" 160 Deer Ln."),
            _createElementVNode("br"),
            _createTextVNode(" Kirbyville, MO 65679"),
            _createElementVNode("br"),
            _createElementVNode("br"),
            _createTextVNode(" --just a little east of Branson, MO. ")
          ], -1)
        ])),
        _: 1
      }),
      _createVNode(_unref(Card), {
        class: "homeCard",
        style: {"min-width":"95%"}
      }, {
        title: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode("Calendar")
        ])),
        content: _withCtx(() => [
          _createVNode(_unref(FullCalendar), { options: _ctx.calendarOptions }, null, 8, ["options"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})